import { Injectable } from '@angular/core';

@Injectable()
export class GlobalUser {
  //private role: string = "test";
  private role: string;

  public getUserId(): string {
    return this.role;
  }

  public setUserId(uid: string): void {
    this.role = uid;
  }
  
}